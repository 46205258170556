import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEM_DISCOUNT_CODE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemDiscountCode = props => {
  const { lineItems, intl } = props;

  const items = lineItems.filter(item => LINE_ITEM_DISCOUNT_CODE == item.code);

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = item.quantity;

        const label =
          quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} x ${quantity}`
            : humanizeLineItemCode(item.code);

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>Discount Code {item.percentage.toFixed()}%</span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemDiscountCode.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDiscountCode;
